.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  /*overflow: hidden;*/
}

.fakeButton{
  text-align: center;
  font-family: 'Permanent Marker', cursive;
  font-size: 40px;
  color: #E2E2E2;
  background-color: #161616;
  padding: 200px 20px 200px 20px;
  border-radius: 10px;
  border-top: 1px solid #E2E2E2;
  border-right: 4px solid #E2E2E2;
  border-bottom: 4px solid #E2E2E2;
  border-left: 1px solid #E2E2E2;
}

.fakeButton:hover{
  border: #9E0031 solid 2px;
  color: #9E0031;
}

.proper{
  /* width: 99%; */
  background-color: #161616;
  height: 100vh;
  overflow-x: hidden;
}

.wheel{
  border-radius: 10px;
  border-top: 1px solid #E2E2E2;
  border-right: 1px solid #E2E2E2;
  border-bottom: 1px solid #E2E2E2;
  border-left: 4px solid #E2E2E2;
  color: #E2E2E2;
  font-family: 'Permanent Marker', cursive;
  font-size: 40px;
  height: 400px;
  width: 65%;
  overflow: hidden;
  margin: auto;
}

.wheel2{
  border-radius: 10px;
  border-top: 1px solid #E2E2E2;
  border-right: 4px solid #E2E2E2;
  border-bottom: 1px solid #E2E2E2;
  border-left: 1px solid #E2E2E2;
  color: #E2E2E2;
  font-family: 'Permanent Marker', cursive;
  font-size: 40px;
  height: 400px;
  width: 65%;
  overflow: hidden;
  margin: auto;
}

.charSelect{
  margin-top: 20px;
}

.portrait{
  text-align: center;
  color: white;
  border-radius: 5px;
  border: white solid 1px;
  margin-bottom: 2px;
  padding: 2px;
  font-size: 20px;
  cursor: crosshair;
}

.portrait:hover{
  border: #9E0031 solid 2px;
  margin-bottom: 0px;
  color: #9E0031;
}

.loading{
  border: grey solid 2px;
  margin-bottom: 0px;
  color: grey;
  padding: 2px;
  font-size: 20px;
  border-radius: 5px;
  text-align: center;
  background-color: #161616;
  margin-left: 25%;
}

.bumper{
  padding-top: 10px;
  padding-bottom: 10px;
}

.madeby{
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  /* margin-top: 90px; */
  color: #E2E2E2;
  font-size: 35px;
  position: absolute;
  bottom: 0;
}

.smalltext{
  font-size: 16px;
  color: #E2E2E2;
}

.grey{
  background-color: #282c34;
  width: 100vw;
  height: 1080px;
  overflow: hidden;
  position: fixed;
}

.left{
  position: absolute;
  right: 1rem;
}

input[type=text]{
  display: block;
  height: 100%;
  width: 100%;
  font-size: large;
}

.proper label{
  display: block;
  color: white;
}

/* #root{
  overflow: hidden;
  position: fixed;
} */

.box{
  border: 2px solid white;
  text-align: center;
}

.popup{
  background-color: rgba(0,0,0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  pointer-events: all;
  z-index: 1;
}

.popup-content{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #31343b;
  border-radius: 10px;
  z-index: 10;
  background-color: #282c34;
  width: 600px;
  max-width: 85%;
  color: white;
  padding: 20px;
  display: block;
}

.close-button{
  border: none;
  outline: none;
  background: none;
  color: white;
  font-weight: bold;
  /* padding-top: 10px; */
  padding-left: 10px;
  padding-right: 10px;
}

.popup-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 5px;
}

hr{
  color: white;
  border-color: white;
  background-color: white;
}

.bold-text{
  font-weight: bold;
}

.reduce{
  max-width: 100%;
}

.popup-content p{
  font-size: 8;
}

.descrip{
  padding-top: 8px;
  padding-bottom: 15px;
  font-size: 15px;
}

.small-words{
  font-size: 16px;
}

.underline{
  font-weight: bold;
  color: white;
  margin-bottom: 5px;
}

.fake-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 5px;
  background-color: #242424;
  color: white;
  padding-bottom: 10px;
}

.info-icon{
  border: none;
  outline: none;
  background: none;
  color: white;
  font-weight: bold;
  /* padding-top: 10px; */
  padding-left: 10px;
  padding-right: 10px;
}

.scroll{
  overflow-x: hidden;
  overflow-y: auto;
  height: 500px;
}

.landing{
  width: 100%;
}

.landing .row{
  margin-left: 0px;
  margin-right: 0px;
}

.banner{
  min-height: 600px;
  background-color: blue;
  width: 100%;
  color: white;
  display: grid;
  place-items: center;
}

.wordBanner{
  min-height: 600px;
  background-color: #282c34;
  width: 100%;
  color: white;
  display: grid;
  place-items: center;
}

.villagerBanner{
  min-height: 600px;
  font-family: 'Permanent Marker', cursive;
  font-size: 40px;
  background-color: #161616;
  width: 100%;
  color: #9E0031;
  display: grid;
  place-items: center;
}

.gamesBanner{
  min-height: 600px;
  background-color: purple;
  width: 100%;
  color: white;
  display: grid;
  place-items: center;
}

.banner h2{
  text-align: center;
  text-shadow: 2px 2px 8px black;
  font-size: 40px;
}

p h2{
  text-align: center;
}

@media (max-width: 960px){
  .banner{
    min-height: 400px;
  }
  .wordBanner{
    min-height: 400px;
  }
  .villagerBanner{
    min-height: 400px;
  }
  .gamesBanner{
    min-height: 400px;
  }
}

.test-card-wrapper{
  width: 200px;
  min-width: 200px;
  display: block;
  padding: 0.5rem;
  flex-basis: 0px;
  box-sizing: border-box;
}

.test-card-body{
  border-radius: 0.5rem;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  border: 1px solid #d8d8d8;
}

.test-card-top{
  background-color: rgb(216, 216, 216);
  overflow: hidden;
  position: relative;
}

.test-card-image-wrapper{
  position: relative;
  overflow: hidden;
  min-height: 180px;
  max-height: 182px;;
}

.test-card-image-wrapper img{
  width: 100%;
  height:fit-content;
}

.test-card-bottom{
  padding: 0.75rem;
}

.test-card-title{
  font-size: 18px;
  line-height: 24px;
  color: 4a4a4a;
  font-weight: 700;
  display: block;
  white-space:pre;
  overflow:hidden;
  text-overflow: ellipsis;
}

.test-card-price-wrapper{
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.test-card-price{
  font-size: 20px;
  line-height: 28px;
  color: #4a4a4a;
  font-weight: 900;
  display: block;
}

.test-card-date{
  font-size: 14px;
  line-height: 20px;
  color: #969696;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

#main-content {
  display: grid;
  /*height:70vh;*/
  margin: 20px auto auto auto;
  grid-template-rows: repeat(2, 0.75fr);
  grid-template-columns: repeat(5, 0.2fr);
  grid-auto-rows: 0.75fr;
  grid-auto-columns: 0.2fr;
  gap: 0rem;
}

.filters{
  float: right;
  margin-right: 100px;
  margin-top: 20px;
  border-radius: 3px;
  width: 200px;
  background-color: white;
  z-index: 3;
}

.searchFilter{
  border-top: 1px solid black;
  border-bottom: 1px solid white;
  border-radius: 0.25rem;
}

.searchFilter label{
  color: black;
  display: flex;
  width: 50%;
  justify-content: center;
}

.filterRow{
  display: flex;
  width: 100%;
  height:calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: large;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  justify-content: space-evenly;
}

.searchFilter select{
  display: inline;
  width: 50%;
  height: 100%;
}

.gameCount{
  text-align: center;
  margin-bottom: 5px;
}

@media screen and (min-width: 0px) and (max-width: 305px){
  #main-content{
    grid-template-columns: repeat(1, 0.2fr);
  }
  .filters{
    display: none;
  }
}

@media screen and (min-width: 306px) and (max-width: 425px){
  #main-content{
    grid-template-columns: repeat(1, 0.2fr);
  }
  .filters{
    display: none;
  }
  .test-card-wrapper{
    margin-left: 40%;
  }
}

@media screen and (min-width: 426px) and (max-width: 700px) {
  #main-content-home{
      grid-template-rows: repeat(3, 0.5fr);
      grid-template-columns: repeat(2, 0.2fr);
  }
  #main-content{
      grid-template-rows: repeat(4, 0.75fr);
      grid-template-columns: repeat(2, 0.2fr);
  }
  .filters{
    display: none;
  }
}

@media screen and (min-width: 701px) and (max-width: 920px){
  .filters{
    display: none;
  }
  #main-content{
    grid-template-rows: repeat(4, 0.75fr);
    grid-template-columns: repeat(3, 0.2fr);
  }
}


@media screen and (min-width: 921px) and (max-width: 1080px){
  #main-content{
    grid-template-rows: repeat(3, 0.75fr);
    grid-template-columns: repeat(4, 0.2fr);
  }
  .filters{
    display: none;
  }
}

@media screen and (min-width: 1081px) and (max-width: 1750px){
  .filters{
    display: none;
  }
}

@media screen and (min-width: 1751px) and (max-width: 2000px){
  .searchFilter{
    display: none;
  }
}

.searchbar{
  margin-top: 15px;
  background-color: white;
  border-radius: 3px;
}

.lies{
  display: block;
  width: 100%;
  height:calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.lies:focus{
  border: 0px;
  outline: none;
}

.logo-wrapper{
  margin-right: auto;
  margin-left: auto;
  display: block;
  margin-bottom: 15vh;
  margin-top: 10px
}

.logo-wrapper2{
  margin-right: auto;
  margin-left: auto;
  display: block;
  margin-bottom: 7vh;
  margin-top: 10px
}

.please{
  text-decoration: none !important;
}

.log{
  height: 200px;
  overflow-y: auto;
  margin-top: 40px;
}

.me{
  height: 260px;
  margin-left: 360px;
  width: auto;
  margin-top: 200px;
  position: relative;
}

.you{
  height: 260px;
  margin-right: 383px;
  float: right;
  margin-top: 78px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.context{
  position: absolute;
  color: white;
}

.lifeyou{
  margin-top: -430px;
  margin-left: 1105px;
}

.lifeme{
  margin-top: -300px;
  margin-left: 355px;
}

.healthBar{
  width: 155px;
  border-radius: 7px;
  border: 2px solid black;
  height: 18px;
  overflow: hidden;
  background-color: rgb(178, 243, 178);
}

.healthBar .life{
  width:100%;
  height:100%;
  background-color: rgb(69, 150, 69);
  border-radius: 6px;
  border: 1px solid green;
}

.percent{
  background-color: grey;
  margin-left:10px;
  font-size: 14px;
  border-radius: 3px;
}

.winGif{
  max-width: 98%;
  max-height: 680px;
}

.squeeze{
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
}

.imgUrl{
  overflow: clip;
  text-overflow: ellipsis;
  max-height: 26px;
  max-width: 95%;
  min-width: 50%;
}

.titleClip{
  overflow: hidden;
  text-overflow: clip;
  max-height: 26px;
}

.form-signin{
  max-width: 330px;
  padding: 15px;
}

.editBox{
  margin-top: 25vh;
}

.darken:hover{
  background-color: #e4e4e4;
}

.checkHolder label{
  color: black;
}

.table-container{
  display: grid;
  grid-template-columns: 1fr 8fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
}